.Service .buttons-holder {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.Service .service-logo {
  max-width: 24px !important;
  max-height: 24px !important;
  margin-right: 10px !important;
  vertical-align: middle; }

.Service .service-data {
  position: relative;
  width: 100%; }

.Service .copy-path {
  display: flex;
  background: none;
  padding: 0;
  border: 0;
  font-size: 16px;
  font-weight: normal !important;
  align-items: center; }
  .Service .copy-path a {
    color: #908a8a; }
  .Service .copy-path button {
    padding: 0;
    border: 0;
    background: none; }
  .Service .copy-path svg {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    display: block; }
  .Service .copy-path + .copy-path {
    margin-top: 5px; }

.Service .bts-path {
  display: flex;
  flex-direction: column; }
