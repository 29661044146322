.Toggle {
  padding: 3px;
  background-color: #e12020;
  width: 36px;
  border-radius: 10px;
  border: none;
  transition: background 0.2s linear;
  cursor: pointer; }
  .Toggle.enabled {
    background-color: #43aa37; }
    .Toggle.enabled:before {
      transform: translateX(15px); }
  .Toggle:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.2s linear; }
