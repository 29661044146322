.terms-services p {
  margin: 0 0 20px; }

.terms-services strong {
  font-weight: bold; }

.terms-services h3 {
  font-weight: bold;
  margin: 0 0 20px; }

.terms-services ul {
  list-style: inside;
  margin: 0 0 20px; }

.terms-services table {
  border-top: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  margin: 0 0 20px; }
  .terms-services table.table-first tbody td:first-child {
    background-color: #F6F6F6; }
  .terms-services table td {
    border-left: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    padding: 10px; }
  .terms-services table thead td {
    background-color: #F6F6F6; }
