@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700);
@import url(https://cloud.typography.com/7298556/7298992/css/fonts.css);
/* BASIC SETTINGS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; }

*:focus {
  outline: none; }

/*Font*/
/*FONTS*/
/*****BREAKPOINTS*****/
body {
  height: 100%;
  font-family: "Cairo", sans-serif;
  line-height: 1.4em;
  font-weight: 400;
  color: #565656;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

input,
#root {
  height: 100%; }
  input > div,
  #root > div {
    height: 100%; }

p {
  line-height: 1.4em; }

input,
select {
  font-size: 1rem;
  font-family: "Cairo", sans-serif;
  color: #565656; }

a {
  color: #4a90e2; }

h1,
h2,
h3,
h4 {
  color: #565656; }

button {
  font-family: "Cairo", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  background-color: #fff;
  color: #565656;
  cursor: pointer; }

html {
  height: 100%; }
  html body#manager #manager-dashboard {
    display: flex;
    height: 100vh; }
    html body#manager #manager-dashboard > section {
      width: 90%;
      min-height: 100%;
      margin: 0 auto;
      background-color: #ffffff; }
    html body#manager #manager-dashboard .content-holder {
      padding: 70px;
      flex-grow: 1;
      overflow: auto;
      max-height: 100vh; }
  html body#manager #sidebar {
    width: 30%;
    padding: 15px 30px; }
    html body#manager #sidebar h3 {
      font-size: 22px;
      text-transform: uppercase;
      font-family: "Verlag A", "Verlag B";
      font-style: normal;
      font-weight: 300;
      margin-bottom: 30px; }
    html body#manager #sidebar .filter {
      margin-bottom: 25px; }
      html body#manager #sidebar .filter h4 {
        text-transform: uppercase;
        font-family: "Verlag A", "Verlag B";
        font-style: normal;
        font-weight: 300;
        margin-bottom: 15px; }
      html body#manager #sidebar .filter ul li {
        display: flex;
        align-content: space-between;
        margin-bottom: 10px; }
        html body#manager #sidebar .filter ul li .name {
          width: 85%;
          font-weight: 600;
          color: #2a3b55; }
        html body#manager #sidebar .filter ul li .number {
          padding: 3px 5px;
          text-align: right;
          width: 15%;
          font-size: 12px;
          border-radius: 8px;
          background-color: #dfe4eb; }
    html body#manager #sidebar button {
      color: #265eab;
      font-weight: 600;
      margin-top: 30px;
      border: 1px solid #c4cdd5;
      background: linear-gradient(#fefefe, #f9fafb);
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      padding: 10px;
      width: 100%; }

.main-holder {
  flex-wrap: nowrap !important;
  display: flex;
  min-height: 100vh; }

.content-holder {
  flex-grow: 1;
  overflow: auto;
  max-height: 100vh; }
  .content-holder .wrapper {
    width: 1040px;
    margin: 0 auto;
    padding: 70px 0; }
    .content-holder .wrapper.loading {
      position: relative; }
      .content-holder .wrapper.loading:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.5);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPGRlZnM+ICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9IjguMDQyJSIgeTE9IjAlIiB4Mj0iNjUuNjgyJSIgeTI9IjIzLjg2NSUiIGlkPSJhIj4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9IjAiIG9mZnNldD0iMCUiLz4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9Ii42MzEiIG9mZnNldD0iNjMuMTQ2JSIvPiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM0YTkwZTIiIG9mZnNldD0iMTAwJSIvPiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4gICAgPC9kZWZzPiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgICAgICAgICAgPHBhdGggZD0iTTM2IDE4YzAtOS45NC04LjA2LTE4LTE4LTE4IiBpZD0iT3ZhbC0yIiBzdHJva2U9InVybCgjYSkiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iICAgICAgICAgICAgICAgICAgICB0eXBlPSJyb3RhdGUiICAgICAgICAgICAgICAgICAgICBmcm9tPSIwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIgICAgICAgICAgICAgICAgICAgIGR1cj0iMC45cyIgICAgICAgICAgICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPiAgICAgICAgICAgIDwvcGF0aD4gICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiM0YTkwZTIiIGN4PSIzNiIgY3k9IjE4IiByPSIxIj4gICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gICAgICAgICAgICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgICAgICAgICAgICAgICAgICAgIHR5cGU9InJvdGF0ZSIgICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiICAgICAgICAgICAgICAgICAgICB0bz0iMzYwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgZHVyPSIwLjlzIiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+ICAgICAgICAgICAgPC9jaXJjbGU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px 100px;
        z-index: 2; }

.title-page {
  font-family: "Cairo", sans-serif;
  font-size: 30px;
  font-weight: 900;
  color: #565656;
  text-transform: none;
  margin: 0 0 40px; }

.container-box {
  margin: 0 0 40px; }

.main-button, .button-select, .down-button {
  font-family: "Cairo", sans-serif;
  background-color: #4a90e2;
  border: none;
  padding: 12px 20px;
  line-height: 100%;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 17px;
  text-transform: none;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block; }
  .main-button:hover, .button-select:hover, .down-button:hover, .main-button:visited, .button-select:visited, .down-button:visited {
    background-color: #357acb;
    color: #fff;
    text-decoration: none !important; }
  .main-button.disabled, .disabled.button-select, .disabled.down-button {
    background-color: #94999c;
    cursor: auto; }

.button-select {
  background-color: #fff;
  color: #4a90e2;
  border: 2px solid #4a90e2;
  padding: 10px 18px; }
  .button-select:hover {
    background-color: #fff;
    color: #3177ca;
    border-color: #3177ca; }
  .button-select.selected {
    background-color: #4a90e2;
    color: #fff; }

.down-button {
  background-color: #e24a4a; }
  .down-button:hover {
    background-color: #ce3c3c; }

.select-field {
  position: relative; }
  .select-field:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid #ccc;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

.search-field {
  position: relative;
  border: 1px solid #4a90e2;
  display: inline-block;
  border-radius: 10px; }
  .search-field svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px; }
    .search-field svg * {
      fill: #4a90e2; }
  .search-field input {
    border: none;
    padding: 5px 10px;
    padding-left: 35px;
    background-color: transparent;
    position: relative;
    width: 100%; }

.services {
  display: flex; }
  .services .service {
    display: flex;
    align-items: center; }
    .services .service + .service {
      margin-left: 10px; }
  .services .service-text {
    margin-right: 5px; }
    .services .service-text .qtd,
    .services .service-text .version {
      display: block;
      text-align: right;
      font-size: 11px;
      color: #818181; }
    .services .service-text .qtd {
      margin-bottom: 10px;
      color: #3c3c3c; }
  .services .service-img img {
    display: block;
    max-width: 40px;
    max-height: 40px; }

.head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 20px; }

.loading {
  position: relative; }
  .loading:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPGRlZnM+ICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9IjguMDQyJSIgeTE9IjAlIiB4Mj0iNjUuNjgyJSIgeTI9IjIzLjg2NSUiIGlkPSJhIj4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9IjAiIG9mZnNldD0iMCUiLz4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9Ii42MzEiIG9mZnNldD0iNjMuMTQ2JSIvPiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM0YTkwZTIiIG9mZnNldD0iMTAwJSIvPiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4gICAgPC9kZWZzPiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgICAgICAgICAgPHBhdGggZD0iTTM2IDE4YzAtOS45NC04LjA2LTE4LTE4LTE4IiBpZD0iT3ZhbC0yIiBzdHJva2U9InVybCgjYSkiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iICAgICAgICAgICAgICAgICAgICB0eXBlPSJyb3RhdGUiICAgICAgICAgICAgICAgICAgICBmcm9tPSIwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIgICAgICAgICAgICAgICAgICAgIGR1cj0iMC45cyIgICAgICAgICAgICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPiAgICAgICAgICAgIDwvcGF0aD4gICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiM0YTkwZTIiIGN4PSIzNiIgY3k9IjE4IiByPSIxIj4gICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gICAgICAgICAgICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgICAgICAgICAgICAgICAgICAgIHR5cGU9InJvdGF0ZSIgICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiICAgICAgICAgICAgICAgICAgICB0bz0iMzYwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgZHVyPSIwLjlzIiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+ICAgICAgICAgICAgPC9jaXJjbGU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px 100px;
    z-index: 2; }

.swal2-title {
  line-height: 120%; }

.box {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(226, 234, 238, 0.3);
  box-shadow: 0 3px 5px rgba(156, 160, 171, 0.2);
  margin-bottom: 30px; }
  .box h3 {
    margin-bottom: 20px; }
  .box .n-type1 {
    font-family: "Verlag A", "Verlag B";
    font-style: normal;
    font-weight: 700;
    font-size: 30px; }
  .box .n-type2 {
    font-size: 17px;
    font-weight: 500; }
  .box p strong {
    font-weight: 600;
    font-size: 13px; }
  .box p .text-opaque {
    font-size: 11px;
    color: #9B9B9B; }

btn-tst {
  float: right;
  font-size: 3em; }

h2 {
  color: #acadae;
  margin: 0;
  font-size: 1.5em; }

.Nav {
  background: url("/static/bitmap.png");
  background-position: center;
  background-size: cover;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0; }

.Table .TableHeader {
  display: flex;
  background-color: #F6F6F6;
  border: 1px solid #E8E8E8; }
  .Table .TableHeader .TableCol {
    padding: 20px;
    font-size: 17px;
    color: #908A8A;
    font-weight: 600;
    flex-grow: 1;
    line-height: 100%; }

.Table .TableRow {
  display: flex;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  align-items: center; }
  .Table .TableRow .TableCol {
    padding: 20px;
    flex-grow: 1;
    align-items: center; }
  .Table .TableRow.table-footer {
    background-color: #fbfbfb;
    padding: 20px; }

.Table .no-item {
  padding: 40px;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8; }
  .Table .no-item .title, .Table .no-item .text {
    display: block;
    text-align: center; }
  .Table .no-item .title {
    font-size: 27px;
    font-weight: bold;
    margin: 0 0 20px; }
  .Table .no-item .text {
    font-size: 20px; }

.Table .loading {
  padding: 20px;
  justify-content: center; }

.Table .no-content {
  padding: 40px;
  width: 100%; }
  .Table .no-content span {
    display: block;
    text-align: center;
    font-size: 20px; }
  .Table .no-content .title {
    font-weight: bold;
    margin: 0 0 15px;
    font-size: 24px; }

.Environment .container-box {
  display: flex;
  justify-content: flex-end; }

.Environment .banner {
  margin-bottom: 30px;
  text-align: center; }
  .Environment .banner a {
    display: inline-block; }

.Environment .create-spaces_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px; }

.EnvironmentItem {
  align-items: center; }
  .EnvironmentItem .environment-name {
    display: block;
    color: #4a90e2;
    margin: 0 0 5px; }
  .EnvironmentItem .deleted {
    font-size: 12px;
    line-height: 16px;
    display: block;
    width: 75px; }
  .EnvironmentItem .icon-status {
    width: 36px;
    height: 36px; }
    .EnvironmentItem .icon-status * {
      fill: #94999c; }
    .EnvironmentItem .icon-status.ON * {
      fill: #00d896; }
    .EnvironmentItem .icon-status.Blocked * {
      fill: #d0021b; }
  .EnvironmentItem .services {
    display: flex; }
    .EnvironmentItem .services .service {
      display: flex;
      align-items: center; }
      .EnvironmentItem .services .service + .service {
        margin-left: 10px; }
    .EnvironmentItem .services .service-text {
      margin-right: 5px; }
      .EnvironmentItem .services .service-text .qtd,
      .EnvironmentItem .services .service-text .version {
        display: block;
        text-align: right;
        font-size: 11px;
        color: #818181; }
      .EnvironmentItem .services .service-text .qtd {
        margin-bottom: 10px;
        color: #3c3c3c; }
    .EnvironmentItem .services .service-img img {
      display: block;
      max-width: 40px;
      max-height: 40px; }

.Status {
  display: flex;
  padding: 20px;
  align-items: center;
  margin: 0 0 50px;
  justify-content: space-between;
  position: relative; }
  .Status .delete-msg {
    font-style: italic; }
  .Status.AllServicesOffline {
    background-color: #f3f3f5; }
  .Status.Building, .Status.DeployingAllServices, .Status.TurningOffAllServices, .Status.TurningOnAllServices {
    background-color: rgba(252, 157, 10, 0.1); }
    .Status.Building .current-status .text, .Status.DeployingAllServices .current-status .text, .Status.TurningOffAllServices .current-status .text, .Status.TurningOnAllServices .current-status .text {
      color: #fc9d0a; }
  .Status.AllServicesOnline {
    background-color: #ebf8f4; }
    .Status.AllServicesOnline .current-status .text {
      color: #2ab92e; }
  .Status .current-status .title {
    display: block;
    margin-bottom: 5px; }
  .Status .current-status .text {
    font-size: 22px;
    font-weight: bold; }
    .Status .current-status .text.running {
      color: #caa630; }
    .Status .current-status .text.off {
      color: #e24a4a; }
    .Status .current-status .text.normal {
      color: #939393; }
  .Status .right-buttons {
    flex-grow: 1;
    text-align: right; }
    .Status .right-buttons.disabled {
      position: relative; }
      .Status .right-buttons.disabled::after {
        position: absolute;
        content: "";
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5); }
  .Status .status-bar {
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 8px;
    width: 100%;
    background-color: #d8dde6; }
    .Status .status-bar .bar {
      position: absolute;
      left: 0;
      top: 0;
      height: 8px;
      width: 0%;
      background: #204079;
      background: linear-gradient(24deg, #204079 0, #118aca 100%);
      transition: width .6s linear; }

.TabButtons {
  display: flex; }
  .TabButtons button {
    flex-grow: 1;
    border: 1px solid #4a90e2;
    border-left: 0;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    color: #4a90e2;
    font-weight: normal !important;
    line-height: 100%; }
    .TabButtons button:first-child {
      border-left: 1px solid #4a90e2;
      border-radius: 5px 0 0 5px; }
    .TabButtons button:last-child {
      border-radius: 0 5px 5px 0; }
    .TabButtons button.selected {
      background-color: #B2D5FF;
      color: #10529F; }

.Robustness .robustness-line + .robustness-line {
  margin-top: 15px; }

.Robustness .robustness-line .robustness-label {
  margin-bottom: 5px; }

.Robustness .robustness-line.disabled {
  position: relative;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
  .Robustness .robustness-line.disabled:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent; }

.Toggle {
  padding: 3px;
  background-color: #e12020;
  width: 36px;
  border-radius: 10px;
  border: none;
  transition: background 0.2s linear;
  cursor: pointer; }
  .Toggle.enabled {
    background-color: #43aa37; }
    .Toggle.enabled:before {
      transform: translateX(15px); }
  .Toggle:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.2s linear; }

.Applications {
  padding-left: 100px; }
  .Applications .filter-applications {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }
    .Applications .filter-applications .filter-title {
      font-weight: bold; }
    .Applications .filter-applications .filter-field {
      position: relative; }
      .Applications .filter-applications .filter-field input {
        padding: 5px 10px;
        padding-left: 40px;
        line-height: 100%;
        border: 1px solid #4A90E2;
        border-radius: 10px; }
        .Applications .filter-applications .filter-field input::-webkit-input-placeholder {
          color: #999; }
        .Applications .filter-applications .filter-field input:-moz-placeholder {
          color: #999; }
        .Applications .filter-applications .filter-field input::-moz-placeholder {
          color: #999; }
      .Applications .filter-applications .filter-field svg {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%); }
        .Applications .filter-applications .filter-field svg * {
          fill: #4A90E2; }
  .Applications .applications-list .app {
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding: 10px 0; }

.Service .buttons-holder {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.Service .service-logo {
  max-width: 24px !important;
  max-height: 24px !important;
  margin-right: 10px !important;
  vertical-align: middle; }

.Service .service-data {
  position: relative;
  width: 100%; }

.Service .copy-path {
  display: flex;
  background: none;
  padding: 0;
  border: 0;
  font-size: 16px;
  font-weight: normal !important;
  align-items: center; }
  .Service .copy-path a {
    color: #908a8a; }
  .Service .copy-path button {
    padding: 0;
    border: 0;
    background: none; }
  .Service .copy-path svg {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    display: block; }
  .Service .copy-path + .copy-path {
    margin-top: 5px; }

.Service .bts-path {
  display: flex;
  flex-direction: column; }

.Container {
  margin-bottom: 30px; }

.ServicesControl {
  position: relative; }
  .ServicesControl button + button {
    margin-left: 20px; }
  .ServicesControl.disable:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); }

.AddField .autocomplete-item {
  padding: 5px 10px; }
  .AddField .autocomplete-item.highlighted {
    background-color: #f0f0f0; }

.AddField .add-label {
  display: block;
  font-weight: bold;
  margin: 0 0 10px; }

.AddField .box-field {
  display: flex;
  align-items: center; }
  .AddField .box-field input {
    width: 400px;
    padding: 5px 10px;
    border: 1px solid #4a90e2;
    height: 43px;
    border-radius: 4px 0 0 4px;
    background-color: #fff; }
  .AddField .box-field button {
    border: 0;
    background-color: #4a90e2;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 40px;
    border-radius: 0 4px 4px 0; }

.AddField .disable-button {
  position: relative; }
  .AddField .disable-button.disabled::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0; }

.Backup .row_backup {
  border-bottom: 1px solid #E8E8E8; }
  .Backup .row_backup .TableCol {
    padding: 15px; }
  .Backup .row_backup .TableRow {
    border-bottom: 0; }

.Backup .button-holder {
  text-align: right; }

.EnvironmentDetail .stage-name {
  margin-bottom: 30px;
  display: inline-block; }

.EnvironmentDetail .badge-blocked,
.EnvironmentDetail .badge-updating {
  display: inline-block;
  background-color: #d0021a;
  color: #fff;
  padding: 3px 10px;
  border-radius: 4px;
  margin-left: 10px; }

.EnvironmentDetail .badge-updating {
  background-color: #FC9D0A; }

.EnvironmentDetail .env-head {
  display: flex;
  justify-content: space-between; }
  .EnvironmentDetail .env-head .col-activate {
    text-align: right; }
    .EnvironmentDetail .env-head .col-activate .main-button {
      margin-bottom: 5px; }
    .EnvironmentDetail .env-head .col-activate .text {
      display: block; }

.EnvironmentDetail .header-services {
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between; }
  .EnvironmentDetail .header-services .title {
    font-size: 25px;
    font-weight: 900; }

.EnvironmentDetail .Table {
  margin: 0 0 30px; }

.EnvironmentDetail .save-changes .cancel {
  background-color: none;
  border: none;
  padding: 0;
  color: #4a90e2; }

.EnvironmentDetail .save-changes .text {
  display: inline-block;
  margin: 0 10px; }

.EnvironmentDetail .tabEnvironment {
  border-bottom: 1px solid #e8e8e8;
  display: flex; }
  .EnvironmentDetail .tabEnvironment .tab-button {
    padding: 10px 10px 8px;
    border: 0;
    background-color: transparent;
    font-weight: normal;
    font-size: 20px;
    border-bottom: 2px solid #fff;
    display: block; }
    .EnvironmentDetail .tabEnvironment .tab-button.active, .EnvironmentDetail .tabEnvironment .tab-button:hover {
      border-bottom: 2px solid #4a90e2; }
    .EnvironmentDetail .tabEnvironment .tab-button + .tab-button {
      margin-left: 10px; }
    .EnvironmentDetail .tabEnvironment .tab-button.disabled {
      color: #ccc; }
    .EnvironmentDetail .tabEnvironment .tab-button.disabled:hover {
      border-bottom: 2px solid #fff; }

.EnvironmentDetail .Access,
.EnvironmentDetail .EnvDetailPage,
.EnvironmentDetail .DeleteSpace {
  margin-top: 60px; }

.EnvironmentDetail button.remove {
  padding: 0;
  border: 0;
  background-color: #fff;
  color: #e24a4a;
  font-weight: normal;
  cursor: pointer; }

.EnvironmentDetail .period-select {
  margin-top: 40px; }

.EnvironmentDetail .SubTab {
  position: relative;
  z-index: 2; }
  .EnvironmentDetail .SubTab .SettingsButton {
    display: block;
    padding: 10px;
    padding-right: 25px;
    position: relative; }
    .EnvironmentDetail .SubTab .SettingsButton svg {
      width: 24px;
      height: 24px;
      margin-top: 8px; }
    .EnvironmentDetail .SubTab .SettingsButton:after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-left: 1px solid #fff;
      border-right: 1px solid #000;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #000;
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      top: calc(50% - 6px); }
  .EnvironmentDetail .SubTab:hover .SettingsButton:after {
    border-left-color: #000;
    border-right-color: #fff;
    border-top-color: #000;
    border-bottom-color: #fff; }
  .EnvironmentDetail .SubTab:hover .SubTabOver {
    display: block; }
  .EnvironmentDetail .SubTab .SubTabOver {
    border: 1px solid #ccc;
    position: absolute;
    left: 0;
    top: 57px;
    width: 150px;
    background-color: #fff;
    display: none; }
    .EnvironmentDetail .SubTab .SubTabOver .tab-button {
      width: 100%;
      text-align: left; }
    .EnvironmentDetail .SubTab .SubTabOver .tab-button + .tab-button {
      margin-left: 0; }

.EnvironmentDetail .DetailsUptime .title {
  font-size: 18px;
  font-weight: bold; }

.EnvironmentDetail .DetailsUptime .text {
  margin: 20px 0; }
  .EnvironmentDetail .DetailsUptime .text strong {
    font-weight: bold; }

.EnvironmentDetail .DetailsUptime .button-change.disabled {
  color: #565656;
  cursor: auto; }

.DangerZone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #e24a4a; }
  .DangerZone .message .title {
    display: block;
    font-weight: bold; }

.MessageHighlight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #d8dde6;
  margin: 0 0 40px; }
  .MessageHighlight .message .title {
    display: block;
    font-weight: bold; }

.AddIp {
  padding: 20px 20px 40px;
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8; }
  .AddIp h3 {
    font-family: "Cairo", sans-serif;
    font-size: 18px;
    font-weight: 900;
    color: #565656;
    text-transform: none;
    margin: 0 0 20px; }
  .AddIp form {
    display: flex;
    align-items: flex-end; }
    .AddIp form > div + div {
      margin-left: 20px; }
  .AddIp .field {
    position: relative; }
    .AddIp .field label {
      display: block;
      margin-bottom: 5px; }
    .AddIp .field input {
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #ccc; }
    .AddIp .field input.error {
      border-color: #e24a4a; }
    .AddIp .field .error_msg {
      display: block;
      position: absolute;
      left: 0;
      bottom: -25px;
      color: #e24a4a;
      background-color: #fbfbfb; }
  .AddIp .period {
    display: inline-block;
    margin: 0 5px;
    font-weight: bold;
    font-size: 20px; }

.TableServices .service-data {
  display: flex;
  align-items: center; }

.TableServices .service-logo {
  max-width: 40px;
  max-height: 40px;
  margin-right: 20px; }

.TableServices .TableCol {
  color: #908a8a;
  display: flex;
  align-items: center; }

.TableServices .service-text .name {
  display: block;
  font-size: 17px;
  font-weight: 600;
  color: #565656;
  margin: 0 0 5px; }

.TableServices .service-text .features span {
  display: inline-block;
  padding: 0 6px;
  line-height: 14px; }
  .TableServices .service-text .features span + span {
    border-left: 1px solid #918c8c; }
  .TableServices .service-text .features span:first-child {
    padding-left: 0; }

.TableServices .edit-button {
  padding: 0;
  border: 0;
  background: none;
  color: #4a90e2; }

.TableServices .cancel-button {
  background: none;
  padding: 0;
  border: none;
  color: #e12020;
  font-size: 12px; }

.TableServices .service-status {
  text-align: center; }
  .TableServices .service-status .status {
    font-size: 12px;
    text-transform: uppercase;
    color: #2cb03d;
    line-height: 14px;
    display: block;
    text-align: center; }

.TableServices .ServiceConfig {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out; }
  .TableServices .ServiceConfig .over-holder {
    display: flex;
    padding: 20px;
    padding-top: 0;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8; }
  .TableServices .ServiceConfig .col {
    width: 50%; }

.TableServices .no-services {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 40px; }
  .TableServices .no-services .title {
    display: block;
    text-align: center;
    font-size: 27px;
    font-weight: bold;
    margin: 0 0 20px; }
  .TableServices .no-services .text {
    display: block;
    text-align: center;
    font-size: 20px; }
    .TableServices .no-services .text button {
      padding: 0;
      background: none;
      border: 0;
      color: #4A90E2; }

.NewService .TableRow {
  border-bottom: 0; }

.NewService .ServiceConfig {
  border-bottom: 1px solid #e8e8e6; }

.cardSelector-wrapper {
  position: relative;
  width: 100%;
  height: auto; }

.cardSelector-label {
  display: block;
  padding: 16px 24px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--basic-level-5);
  background: transparent;
  transition: all 250ms ease-in-out;
  overflow: hidden;
  border-radius: 4px; }
  .cardSelector-label.error {
    border-color: var(--feedbacks-level-2);
    background-color: var(--feedbacks-level-2);
    color: #fff; }
  .cardSelector-label::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: var(--primary-level-3);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: inherit;
    transition: all 250ms ease-in-out;
    transform: scaleY(0);
    transform-origin: bottom;
    border-radius: 5px; }
  .cardSelector-label:hover {
    cursor: pointer;
    border-color: var(--primary-level-3);
    color: var(--primary-level-3); }
  .cardSelector-label__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: left; }
  .cardSelector-label__text {
    font-size: 16px; }

.cardSelector-input {
  visibility: hidden;
  position: absolute; }
  .cardSelector-input:checked ~ .cardSelector-label {
    color: #fff; }
    .cardSelector-input:checked ~ .cardSelector-label::after {
      transform: scale(1);
      border-radius: 5px; }

.createEnvironment {
  display: flex;
  align-items: stretch;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden; }
  .createEnvironment-container {
    padding: 72px 0;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    max-width: 1140px;
    margin: 0 auto; }
  .createEnvironment-header {
    margin-bottom: 24px; }
    .createEnvironment-header__title {
      color: var(--basic-level-1);
      font-weight: 700;
      font-size: 28px; }
  .createEnvironment-content__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 48px; }
  .createEnvironment-content__form {
    width: 50%;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-columns: calc(100% / 3 - 48px);
    grid-row: 400px;
    margin-bottom: 64px; }
    .createEnvironment-content__form-actions {
      grid-column: 1 / span 3; }
    .createEnvironment-content__form-button {
      -moz-appearance: none;
      -o-appearance: none;
      -ms-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding: 8px 24px;
      background: var(--primary-level-2);
      color: #fff;
      transition: all 250ms ease;
      border: none;
      border-radius: 4px;
      font-size: 16px; }
      .createEnvironment-content__form-button.is-disabled {
        background: var(--basic-level-3); }
        .createEnvironment-content__form-button.is-disabled:hover {
          cursor: none;
          background: var(--basic-level-3); }
      .createEnvironment-content__form-button:hover {
        cursor: pointer;
        background: var(--primary-level-3); }

.SettingsMenu {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  margin: 0 0 50px; }
  .SettingsMenu .menu-button {
    border: 0;
    margin: 0 10px 0 0;
    padding: 10px;
    background: none;
    font-size: 22px;
    font-weight: normal !important;
    border-bottom: 2px solid transparent;
    height: 64px; }
    .SettingsMenu .menu-button:hover, .SettingsMenu .menu-button.active {
      border-color: #4a90e2; }
  .SettingsMenu .menu-dropdown {
    position: relative; }
    .SettingsMenu .menu-dropdown .menu-button {
      position: relative;
      z-index: 2;
      background: #fff;
      border-radius: 4px 4px 0 0; }
      .SettingsMenu .menu-dropdown .menu-button:hover {
        border: 1px solid #ccc;
        border-bottom: 0; }
      .SettingsMenu .menu-dropdown .menu-button svg {
        width: 10px;
        height: 10px; }
        .SettingsMenu .menu-dropdown .menu-button svg * {
          fill: #565659; }
    .SettingsMenu .menu-dropdown.active {
      border-bottom: 2px solid #4a90e2; }
    .SettingsMenu .menu-dropdown:hover .menu-button {
      border: 1px solid #ccc;
      border-bottom: 0; }
    .SettingsMenu .menu-dropdown:hover .menu-submenu {
      visibility: visible; }
    .SettingsMenu .menu-dropdown .menu-submenu {
      position: absolute;
      border: 1px solid #ccc;
      padding: 10px;
      background: #fff;
      top: 62px;
      z-index: 1;
      border-radius: 0 4px 4px 4px;
      visibility: hidden; }
      .SettingsMenu .menu-dropdown .menu-submenu .submenu-button {
        background: none;
        border: 0;
        padding: 5px;
        font-weight: normal !important;
        white-space: nowrap;
        color: #5c9ce6; }
        .SettingsMenu .menu-dropdown .menu-submenu .submenu-button:hover {
          color: #777; }

.AllUsers .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 20px; }

.Card {
  border: 1px solid #ebe8e8;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box; }
  .Card.highlight {
    box-shadow: 0 0 0 5px #b2d5ff inset;
    border: 1px solid #b2d5ff; }
  .Card.pack_ece {
    display: none; }
  .Card.ece.pack_ece {
    display: block; }

.CardList {
  display: flex;
  flex-wrap: wrap;
  margin: -13px;
  position: relative; }
  .CardList .Card {
    width: 240px;
    margin: 13px; }
  .CardList .disable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center; }

.SectionHeader {
  margin: 0 0 30px; }
  .SectionHeader .section-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 5px; }
  .SectionHeader .section-description {
    font-size: 16px; }

.Input {
  position: relative; }
  .Input input, .Input select {
    display: block;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #ebe8e8;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    z-index: 2; }
    .Input input.error, .Input select.error {
      border-color: #e24a4a; }
    .Input input:disabled, .Input select:disabled {
      background-color: #f0f0f0; }
    .Input input::-webkit-input-placeholder, .Input select::-webkit-input-placeholder {
      color: #999; }
    .Input input::-ms-input-placeholder, .Input select::-ms-input-placeholder {
      color: #999; }
    .Input input::placeholder, .Input select::placeholder {
      color: #999; }
  .Input::before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #757575;
    border-bottom: 0 solid transparent;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; }
  .Input select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font-size: 1rem;
    background-color: transparent; }
  .Input .error-message {
    color: #e24a4a;
    position: absolute;
    white-space: nowrap; }

.User .button-back {
  margin: 0 0 30px;
  display: inline-block; }

.User .field {
  margin: 0 0 20px; }
  .User .field label {
    display: block;
    margin: 0 0 5px;
    font-weight: bold; }

.User .user-role {
  margin: 0 0 40px; }
  .User .user-role .title {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold; }
  .User .user-role .description {
    display: block;
    margin-bottom: 20px;
    min-height: 66px; }
  .User .user-role .button-select {
    width: 100%; }

.User .invite {
  display: flex;
  align-items: center; }
  .User .invite .cancel {
    background-color: none;
    border: none;
    padding: 0;
    color: #4a90e2; }
  .User .invite .text {
    display: inline-block;
    margin: 0 10px; }
  .User .invite .box-disable {
    position: relative; }
    .User .invite .box-disable.disabled:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5); }

.User .password-field {
  border: 1px solid #ebe8e8;
  border-radius: 4px;
  overflow: hidden; }
  .User .password-field .ReactPasswordStrength-input {
    padding: 5px 10px;
    font-size: 16px; }
  .User .password-field .ReactPasswordStrength-strength-bar {
    top: 0; }

.User .password-rules {
  margin: 0 0 20px; }
  .User .password-rules strong {
    font-weight: bold; }

.UserSpaces .button-back {
  display: inline-block;
  margin: 0 0 20px; }

.UserSpaces .head-user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 40px; }
  .UserSpaces .head-user .title-page {
    margin: 0 0 20px; }

.UserSpaces .Table-items .space .space-name span {
  display: block; }

.UserSpaces button.remove {
  padding: 0;
  border: 0;
  background-color: #fff;
  color: #e24a4a;
  font-weight: normal;
  cursor: pointer; }

.UserSpaces .Container {
  margin: 0 0 60px; }

.UserSpaces .danger-zone {
  border: 1px solid #e24a4a;
  padding: 20px;
  display: flex;
  justify-content: space-between; }
  .UserSpaces .danger-zone .text span {
    display: block; }
  .UserSpaces .danger-zone .text .title {
    font-weight: bold; }
  .UserSpaces .danger-zone .main-button {
    background-color: #e24a4a; }

.Team .button-back {
  margin: 0 0 30px;
  display: inline-block; }

.Team .field {
  margin: 0 0 20px; }
  .Team .field label {
    display: block;
    margin: 0 0 5px;
    font-weight: bold; }

.Team .button-holder {
  display: flex;
  align-items: center; }
  .Team .button-holder span {
    margin: 0 10px; }

.Team .box-disable {
  position: relative; }
  .Team .box-disable.disabled:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5); }

.CreditCard .title-page {
  margin-top: 30px; }

.CreditCard .field {
  margin-bottom: 25px;
  width: 50%; }
  .CreditCard .field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px; }
  .CreditCard .field .Input {
    width: 50%; }
    .CreditCard .field .Input.number {
      width: 170px; }
    .CreditCard .field .Input.big-number {
      width: 340px; }
  .CreditCard .field input,
  .CreditCard .field select {
    display: block;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #ebe8e8;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    z-index: 2; }
    .CreditCard .field input.error,
    .CreditCard .field select.error {
      border-color: #e24a4a; }
    .CreditCard .field input:disabled,
    .CreditCard .field select:disabled {
      background-color: #f0f0f0; }
    .CreditCard .field input::-webkit-input-placeholder, .CreditCard .field select::-webkit-input-placeholder {
      color: #999; }
    .CreditCard .field input::-ms-input-placeholder, .CreditCard .field select::-ms-input-placeholder {
      color: #999; }
    .CreditCard .field input::placeholder,
    .CreditCard .field select::placeholder {
      color: #999; }
  .CreditCard .field .error-msg {
    color: #e24a4a; }

.CreditCard .bt-cancel {
  background: none;
  border: 0;
  padding: 0;
  color: #4a90e2;
  font-weight: normal; }

.CreditCard .button-holder {
  display: flex;
  align-items: center; }
  .CreditCard .button-holder span {
    margin: 0 10px; }

.CreditCard .box-disable {
  position: relative; }
  .CreditCard .box-disable.disabled:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5); }

.SelectBuild {
  display: flex; }
  .SelectBuild input {
    display: none !important; }
    .SelectBuild input:checked + label:before {
      background-color: #4a90e2; }
  .SelectBuild label {
    position: relative;
    padding: 3px 0 3px 35px;
    cursor: pointer;
    font-weight: normal !important; }
    .SelectBuild label:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      box-shadow: 0 0 0 3px #fff inset;
      border: 2px solid #4a90e2;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .SelectBuild .element + .element {
    margin-left: 40px; }

.CreateAccount {
  padding: 120px 170px;
  overflow: auto;
  max-height: 100vh; }
  .CreateAccount .logo_open_galaxy {
    margin: 0 0 20px; }
  .CreateAccount .head-line {
    font-size: 20px;
    margin: 0 0 40px; }
  .CreateAccount .field {
    margin: 0 0 25px; }
    .CreateAccount .field label {
      display: block;
      font-weight: bold;
      margin: 0 0 5px; }
    .CreateAccount .field .error_msg {
      color: #e12020; }
    .CreateAccount .field .field_input {
      display: block;
      padding: 5px 10px;
      width: 100%;
      border: 1px solid #ebe8e8;
      border-radius: 4px;
      background-color: #fff;
      position: relative;
      z-index: 2; }
      .CreateAccount .field .field_input.error {
        border: 1px solid #e12020; }
  .CreateAccount.loading {
    position: relative; }
    .CreateAccount.loading:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5);
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPGRlZnM+ICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9IjguMDQyJSIgeTE9IjAlIiB4Mj0iNjUuNjgyJSIgeTI9IjIzLjg2NSUiIGlkPSJhIj4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9IjAiIG9mZnNldD0iMCUiLz4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9Ii42MzEiIG9mZnNldD0iNjMuMTQ2JSIvPiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM0YTkwZTIiIG9mZnNldD0iMTAwJSIvPiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4gICAgPC9kZWZzPiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgICAgICAgICAgPHBhdGggZD0iTTM2IDE4YzAtOS45NC04LjA2LTE4LTE4LTE4IiBpZD0iT3ZhbC0yIiBzdHJva2U9InVybCgjYSkiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iICAgICAgICAgICAgICAgICAgICB0eXBlPSJyb3RhdGUiICAgICAgICAgICAgICAgICAgICBmcm9tPSIwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIgICAgICAgICAgICAgICAgICAgIGR1cj0iMC45cyIgICAgICAgICAgICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPiAgICAgICAgICAgIDwvcGF0aD4gICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiM0YTkwZTIiIGN4PSIzNiIgY3k9IjE4IiByPSIxIj4gICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gICAgICAgICAgICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgICAgICAgICAgICAgICAgICAgIHR5cGU9InJvdGF0ZSIgICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiICAgICAgICAgICAgICAgICAgICB0bz0iMzYwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgZHVyPSIwLjlzIiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+ICAgICAgICAgICAgPC9jaXJjbGU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100px 100px;
      z-index: 20; }

.notfound {
  display: flex;
  align-items: flex-end; }
  .notfound p {
    font-size: 20px;
    margin: 0 0 20px; }
  .notfound ul {
    margin: 0 0 30px;
    list-style: inside; }
  .notfound img {
    margin-right: 40px; }

.terms-services p {
  margin: 0 0 20px; }

.terms-services strong {
  font-weight: bold; }

.terms-services h3 {
  font-weight: bold;
  margin: 0 0 20px; }

.terms-services ul {
  list-style: inside;
  margin: 0 0 20px; }

.terms-services table {
  border-top: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  margin: 0 0 20px; }
  .terms-services table.table-first tbody td:first-child {
    background-color: #F6F6F6; }
  .terms-services table td {
    border-left: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    padding: 10px; }
  .terms-services table thead td {
    background-color: #F6F6F6; }

.privacy-policy p {
  margin: 0 0 20px; }

.privacy-policy strong {
  font-weight: bold; }

.privacy-policy h3 {
  font-weight: bold;
  margin: 0 0 20px; }

.privacy-policy ul {
  list-style: inside;
  margin: 0 0 20px; }

.Plugins .Plugin {
  border: 1px solid #ccc;
  width: 280px; }
  .Plugins .Plugin.card_1 .title {
    background-image: url("/static/card_1.png"); }
  .Plugins .Plugin.card_2 .title {
    background-image: url("/static/card_2.png"); }
  .Plugins .Plugin.card_3 .title {
    background-image: url("/static/card_3.png"); }
  .Plugins .Plugin + .Plugin {
    margin-left: 25px; }
  .Plugins .Plugin .title {
    background-color: #000;
    height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-position: center; }
    .Plugins .Plugin .title .name {
      padding-left: 108px;
      color: #fff; }
    .Plugins .Plugin .title .category {
      padding-left: 108px;
      color: #fff;
      font-size: 14px; }
  .Plugins .Plugin .description {
    display: block;
    padding: 20px; }

.Plugins .Plugin_Plus {
  border: 1px solid #ccc;
  width: 280px;
  padding: 20px; }
  .Plugins .Plugin_Plus .container-img {
    height: 60px;
    margin-bottom: 10px;
    text-align: center; }
  .Plugins .Plugin_Plus + .Plugin_Plus {
    margin-left: 25px; }
  .Plugins .Plugin_Plus .title {
    margin-bottom: 20px; }
    .Plugins .Plugin_Plus .title .name {
      display: block;
      font-weight: bold;
      font-size: 18px; }
    .Plugins .Plugin_Plus .title .category {
      font-size: 14px;
      color: #9B9B9B; }
  .Plugins .Plugin_Plus .description {
    display: block;
    min-height: 70px;
    margin-bottom: 10px; }
  .Plugins .Plugin_Plus .ask-button {
    text-decoration: none;
    padding: 0;
    background: transparent;
    border: 0;
    font-weight: normal;
    color: #4a90e2; }

.Plugins .cards {
  display: flex;
  margin-bottom: 40px; }

.Plugins .MessagePurchase span {
  background-color: #ffe8ab;
  padding: 20px;
  margin-bottom: 30px;
  display: block; }

.Plugins .MessagePurchase a {
  font-weight: bold;
  text-decoration: none; }

.Plugins .SpacesPlugins .TableHeader .TableCol {
  text-align: center; }
  .Plugins .SpacesPlugins .TableHeader .TableCol:first-child {
    text-align: left; }

.Plugins .SpacePlugin .TableCol {
  text-align: center; }
  .Plugins .SpacePlugin .TableCol input {
    height: auto; }
  .Plugins .SpacePlugin .TableCol:first-child {
    text-align: left; }

.Plugins .TableRow.total {
  background-color: #F6F6F6; }
  .Plugins .TableRow.total .TableCol {
    text-align: right;
    font-weight: bold; }

.Plugins .no-item .title {
  margin-bottom: 0; }

.Plugins .agreeCheckbox input {
  height: auto;
  margin: 0 10px 20px 0; }

.Plugins .purchasing-message {
  margin: 0 0 20px; }

.Plugins .row_button {
  position: relative; }
  .Plugins .row_button.disable::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0; }

.ServicesUptime .link-back {
  margin-bottom: 20px;
  display: inline-block; }

.ServicesUptime .day-select {
  display: flex;
  margin: 0 0 40px; }
  .ServicesUptime .day-select .day + .day {
    margin-left: 20px; }
  .ServicesUptime .day-select .day input {
    height: auto;
    margin-right: 8px; }

.ServicesUptime .schedule-container {
  display: flex;
  margin: 0 0 40px;
  align-items: flex-end; }
  .ServicesUptime .schedule-container .schedule + .schedule {
    margin-left: 20px; }
  .ServicesUptime .schedule-container .schedule label {
    display: block;
    margin-bottom: 5px; }
  .ServicesUptime .schedule-container .time-estimated {
    margin-left: 20px;
    margin-bottom: 7px; }

.ServicesUptime .button-holder {
  display: flex;
  align-items: center; }
  .ServicesUptime .button-holder span {
    margin: 0 10px; }

.ServicesUptime .Table .TableCol:last-child {
  text-align: right; }

.ServicesUptime .Table .total {
  background-color: #F6F6F6;
  align-items: flex-start; }
  .ServicesUptime .Table .total .TableCol {
    flex-direction: column;
    display: flex;
    align-items: flex-end; }
  .ServicesUptime .Table .total .text-total {
    display: inline-block;
    text-align: left; }
    .ServicesUptime .Table .total .text-total strong {
      display: block;
      font-weight: bold; }
  .ServicesUptime .Table .total .price {
    font-weight: bold;
    display: block;
    font-size: 18px; }

.ServicesUptime .agreeCheckbox {
  margin-bottom: 20px; }
  .ServicesUptime .agreeCheckbox input {
    height: auto;
    margin: 0 10px 20px 0; }

.ServicesUptime .row-total {
  display: flex;
  justify-content: space-between;
  width: 580px; }
  .ServicesUptime .row-total .price-now {
    font-weight: bold; }
  .ServicesUptime .row-total + .row-total {
    margin-top: 20px; }

.ServicesUptime .MessagePurchase span {
  background-color: #ffe8ab;
  padding: 20px;
  margin-bottom: 30px;
  display: block; }

.ServicesUptime .MessagePurchase a {
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  margin-left: 10px; }

.ServicesUptime .its-not {
  display: block;
  margin-bottom: 30px;
  font-style: italic; }

.ScheduleUpdate {
  margin-bottom: 50px; }
  .ScheduleUpdate .select-schedule {
    margin-bottom: 10px; }
    .ScheduleUpdate .select-schedule input {
      height: auto;
      margin-right: 10px; }

.BillingDate .title-page {
  margin-top: 40px; }

.BillingDate .select-date {
  display: flex;
  margin-bottom: 40px; }
  .BillingDate .select-date .billing-date input {
    height: auto;
    margin-right: 10px; }
  .BillingDate .select-date .billing-date + .billing-date {
    margin-left: 40px; }

.BillingDate .button-holder {
  display: flex;
  align-items: center; }
  .BillingDate .button-holder span {
    margin: 0 15px; }

.BillingDate .bt-link {
  padding: 0;
  background: transparent;
  border: 0;
  color: #4a90e2; }

