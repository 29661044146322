.privacy-policy p {
  margin: 0 0 20px; }

.privacy-policy strong {
  font-weight: bold; }

.privacy-policy h3 {
  font-weight: bold;
  margin: 0 0 20px; }

.privacy-policy ul {
  list-style: inside;
  margin: 0 0 20px; }
