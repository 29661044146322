.Team .button-back {
  margin: 0 0 30px;
  display: inline-block; }

.Team .field {
  margin: 0 0 20px; }
  .Team .field label {
    display: block;
    margin: 0 0 5px;
    font-weight: bold; }

.Team .button-holder {
  display: flex;
  align-items: center; }
  .Team .button-holder span {
    margin: 0 10px; }

.Team .box-disable {
  position: relative; }
  .Team .box-disable.disabled:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5); }
