.User .button-back {
  margin: 0 0 30px;
  display: inline-block; }

.User .field {
  margin: 0 0 20px; }
  .User .field label {
    display: block;
    margin: 0 0 5px;
    font-weight: bold; }

.User .user-role {
  margin: 0 0 40px; }
  .User .user-role .title {
    display: block;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold; }
  .User .user-role .description {
    display: block;
    margin-bottom: 20px;
    min-height: 66px; }
  .User .user-role .button-select {
    width: 100%; }

.User .invite {
  display: flex;
  align-items: center; }
  .User .invite .cancel {
    background-color: none;
    border: none;
    padding: 0;
    color: #4a90e2; }
  .User .invite .text {
    display: inline-block;
    margin: 0 10px; }
  .User .invite .box-disable {
    position: relative; }
    .User .invite .box-disable.disabled:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.5); }

.User .password-field {
  border: 1px solid #ebe8e8;
  border-radius: 4px;
  overflow: hidden; }
  .User .password-field .ReactPasswordStrength-input {
    padding: 5px 10px;
    font-size: 16px; }
  .User .password-field .ReactPasswordStrength-strength-bar {
    top: 0; }

.User .password-rules {
  margin: 0 0 20px; }
  .User .password-rules strong {
    font-weight: bold; }
