.Robustness .robustness-line + .robustness-line {
  margin-top: 15px; }

.Robustness .robustness-line .robustness-label {
  margin-bottom: 5px; }

.Robustness .robustness-line.disabled {
  position: relative;
  filter: grayscale(100%); }
  .Robustness .robustness-line.disabled:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: transparent; }
