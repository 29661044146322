.Table .TableHeader {
  display: flex;
  background-color: #F6F6F6;
  border: 1px solid #E8E8E8; }
  .Table .TableHeader .TableCol {
    padding: 20px;
    font-size: 17px;
    color: #908A8A;
    font-weight: 600;
    flex-grow: 1;
    line-height: 100%; }

.Table .TableRow {
  display: flex;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  align-items: center; }
  .Table .TableRow .TableCol {
    padding: 20px;
    flex-grow: 1;
    align-items: center; }
  .Table .TableRow.table-footer {
    background-color: #fbfbfb;
    padding: 20px; }

.Table .no-item {
  padding: 40px;
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8; }
  .Table .no-item .title, .Table .no-item .text {
    display: block;
    text-align: center; }
  .Table .no-item .title {
    font-size: 27px;
    font-weight: bold;
    margin: 0 0 20px; }
  .Table .no-item .text {
    font-size: 20px; }

.Table .loading {
  padding: 20px;
  justify-content: center; }

.Table .no-content {
  padding: 40px;
  width: 100%; }
  .Table .no-content span {
    display: block;
    text-align: center;
    font-size: 20px; }
  .Table .no-content .title {
    font-weight: bold;
    margin: 0 0 15px;
    font-size: 24px; }
