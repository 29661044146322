.Applications {
  padding-left: 100px; }
  .Applications .filter-applications {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }
    .Applications .filter-applications .filter-title {
      font-weight: bold; }
    .Applications .filter-applications .filter-field {
      position: relative; }
      .Applications .filter-applications .filter-field input {
        padding: 5px 10px;
        padding-left: 40px;
        line-height: 100%;
        border: 1px solid #4A90E2;
        border-radius: 10px; }
        .Applications .filter-applications .filter-field input::-webkit-input-placeholder {
          color: #999; }
        .Applications .filter-applications .filter-field input:-moz-placeholder {
          color: #999; }
        .Applications .filter-applications .filter-field input::-moz-placeholder {
          color: #999; }
      .Applications .filter-applications .filter-field svg {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%); }
        .Applications .filter-applications .filter-field svg * {
          fill: #4A90E2; }
  .Applications .applications-list .app {
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding: 10px 0; }
