.cardSelector-wrapper {
  position: relative;
  width: 100%;
  height: auto; }

.cardSelector-label {
  display: block;
  padding: 16px 24px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--basic-level-5);
  background: transparent;
  transition: all 250ms ease-in-out;
  overflow: hidden;
  border-radius: 4px; }
  .cardSelector-label.error {
    border-color: var(--feedbacks-level-2);
    background-color: var(--feedbacks-level-2);
    color: #fff; }
  .cardSelector-label::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: var(--primary-level-3);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: inherit;
    transition: all 250ms ease-in-out;
    transform: scaleY(0);
    transform-origin: bottom;
    border-radius: 5px; }
  .cardSelector-label:hover {
    cursor: pointer;
    border-color: var(--primary-level-3);
    color: var(--primary-level-3); }
  .cardSelector-label__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: left; }
  .cardSelector-label__text {
    font-size: 16px; }

.cardSelector-input {
  visibility: hidden;
  position: absolute; }
  .cardSelector-input:checked ~ .cardSelector-label {
    color: #fff; }
    .cardSelector-input:checked ~ .cardSelector-label::after {
      transform: scale(1);
      border-radius: 5px; }
