/* BASIC SETTINGS */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700");
@import url("https://cloud.typography.com/7298556/7298992/css/fonts.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; }

*:focus {
  outline: none; }

/*Font*/
/*FONTS*/
/*****BREAKPOINTS*****/
body {
  height: 100%;
  font-family: "Cairo", sans-serif;
  line-height: 1.4em;
  font-weight: 400;
  color: #565656;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

input,
#root {
  height: 100%; }
  input > div,
  #root > div {
    height: 100%; }

p {
  line-height: 1.4em; }

input,
select {
  font-size: 1rem;
  font-family: "Cairo", sans-serif;
  color: #565656; }

a {
  color: #4a90e2; }

h1,
h2,
h3,
h4 {
  color: #565656; }

button {
  font-family: "Cairo", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  background-color: #fff;
  color: #565656;
  cursor: pointer; }

html {
  height: 100%; }
  html body#manager #manager-dashboard {
    display: flex;
    height: 100vh; }
    html body#manager #manager-dashboard > section {
      width: 90%;
      min-height: 100%;
      margin: 0 auto;
      background-color: #ffffff; }
    html body#manager #manager-dashboard .content-holder {
      padding: 70px;
      flex-grow: 1;
      overflow: auto;
      max-height: 100vh; }
  html body#manager #sidebar {
    width: 30%;
    padding: 15px 30px; }
    html body#manager #sidebar h3 {
      font-size: 22px;
      text-transform: uppercase;
      font-family: "Verlag A", "Verlag B";
      font-style: normal;
      font-weight: 300;
      margin-bottom: 30px; }
    html body#manager #sidebar .filter {
      margin-bottom: 25px; }
      html body#manager #sidebar .filter h4 {
        text-transform: uppercase;
        font-family: "Verlag A", "Verlag B";
        font-style: normal;
        font-weight: 300;
        margin-bottom: 15px; }
      html body#manager #sidebar .filter ul li {
        display: flex;
        align-content: space-between;
        margin-bottom: 10px; }
        html body#manager #sidebar .filter ul li .name {
          width: 85%;
          font-weight: 600;
          color: #2a3b55; }
        html body#manager #sidebar .filter ul li .number {
          padding: 3px 5px;
          text-align: right;
          width: 15%;
          font-size: 12px;
          border-radius: 8px;
          background-color: #dfe4eb; }
    html body#manager #sidebar button {
      color: #265eab;
      font-weight: 600;
      margin-top: 30px;
      border: 1px solid #c4cdd5;
      background: linear-gradient(#fefefe, #f9fafb);
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      padding: 10px;
      width: 100%; }

.main-holder {
  flex-wrap: nowrap !important;
  display: flex;
  min-height: 100vh; }

.content-holder {
  flex-grow: 1;
  overflow: auto;
  max-height: 100vh; }
  .content-holder .wrapper {
    width: 1040px;
    margin: 0 auto;
    padding: 70px 0; }
    .content-holder .wrapper.loading {
      position: relative; }
      .content-holder .wrapper.loading:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.5);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPGRlZnM+ICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9IjguMDQyJSIgeTE9IjAlIiB4Mj0iNjUuNjgyJSIgeTI9IjIzLjg2NSUiIGlkPSJhIj4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9IjAiIG9mZnNldD0iMCUiLz4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9Ii42MzEiIG9mZnNldD0iNjMuMTQ2JSIvPiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM0YTkwZTIiIG9mZnNldD0iMTAwJSIvPiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4gICAgPC9kZWZzPiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgICAgICAgICAgPHBhdGggZD0iTTM2IDE4YzAtOS45NC04LjA2LTE4LTE4LTE4IiBpZD0iT3ZhbC0yIiBzdHJva2U9InVybCgjYSkiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iICAgICAgICAgICAgICAgICAgICB0eXBlPSJyb3RhdGUiICAgICAgICAgICAgICAgICAgICBmcm9tPSIwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIgICAgICAgICAgICAgICAgICAgIGR1cj0iMC45cyIgICAgICAgICAgICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPiAgICAgICAgICAgIDwvcGF0aD4gICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiM0YTkwZTIiIGN4PSIzNiIgY3k9IjE4IiByPSIxIj4gICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gICAgICAgICAgICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgICAgICAgICAgICAgICAgICAgIHR5cGU9InJvdGF0ZSIgICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiICAgICAgICAgICAgICAgICAgICB0bz0iMzYwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgZHVyPSIwLjlzIiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+ICAgICAgICAgICAgPC9jaXJjbGU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px 100px;
        z-index: 2; }

.title-page {
  font-family: "Cairo", sans-serif;
  font-size: 30px;
  font-weight: 900;
  color: #565656;
  text-transform: none;
  margin: 0 0 40px; }

.container-box {
  margin: 0 0 40px; }

.main-button, .button-select, .down-button {
  font-family: "Cairo", sans-serif;
  background-color: #4a90e2;
  border: none;
  padding: 12px 20px;
  line-height: 100%;
  color: #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 17px;
  text-transform: none;
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block; }
  .main-button:hover, .button-select:hover, .down-button:hover, .main-button:visited, .button-select:visited, .down-button:visited {
    background-color: #357acb;
    color: #fff;
    text-decoration: none !important; }
  .main-button.disabled, .disabled.button-select, .disabled.down-button {
    background-color: #94999c;
    cursor: auto; }

.button-select {
  background-color: #fff;
  color: #4a90e2;
  border: 2px solid #4a90e2;
  padding: 10px 18px; }
  .button-select:hover {
    background-color: #fff;
    color: #3177ca;
    border-color: #3177ca; }
  .button-select.selected {
    background-color: #4a90e2;
    color: #fff; }

.down-button {
  background-color: #e24a4a; }
  .down-button:hover {
    background-color: #ce3c3c; }

.select-field {
  position: relative; }
  .select-field:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid #ccc;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

.search-field {
  position: relative;
  border: 1px solid #4a90e2;
  display: inline-block;
  border-radius: 10px; }
  .search-field svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px; }
    .search-field svg * {
      fill: #4a90e2; }
  .search-field input {
    border: none;
    padding: 5px 10px;
    padding-left: 35px;
    background-color: transparent;
    position: relative;
    width: 100%; }

.services {
  display: flex; }
  .services .service {
    display: flex;
    align-items: center; }
    .services .service + .service {
      margin-left: 10px; }
  .services .service-text {
    margin-right: 5px; }
    .services .service-text .qtd,
    .services .service-text .version {
      display: block;
      text-align: right;
      font-size: 11px;
      color: #818181; }
    .services .service-text .qtd {
      margin-bottom: 10px;
      color: #3c3c3c; }
  .services .service-img img {
    display: block;
    max-width: 40px;
    max-height: 40px; }

.head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 20px; }

.loading {
  position: relative; }
  .loading:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHZpZXdCb3g9IjAgMCAzOCAzOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPGRlZnM+ICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9IjguMDQyJSIgeTE9IjAlIiB4Mj0iNjUuNjgyJSIgeTI9IjIzLjg2NSUiIGlkPSJhIj4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9IjAiIG9mZnNldD0iMCUiLz4gICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjNGE5MGUyIiBzdG9wLW9wYWNpdHk9Ii42MzEiIG9mZnNldD0iNjMuMTQ2JSIvPiAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiM0YTkwZTIiIG9mZnNldD0iMTAwJSIvPiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4gICAgPC9kZWZzPiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSI+ICAgICAgICAgICAgPHBhdGggZD0iTTM2IDE4YzAtOS45NC04LjA2LTE4LTE4LTE4IiBpZD0iT3ZhbC0yIiBzdHJva2U9InVybCgjYSkiIHN0cm9rZS13aWR0aD0iMiI+ICAgICAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iICAgICAgICAgICAgICAgICAgICB0eXBlPSJyb3RhdGUiICAgICAgICAgICAgICAgICAgICBmcm9tPSIwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIgICAgICAgICAgICAgICAgICAgIGR1cj0iMC45cyIgICAgICAgICAgICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPiAgICAgICAgICAgIDwvcGF0aD4gICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiM0YTkwZTIiIGN4PSIzNiIgY3k9IjE4IiByPSIxIj4gICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gICAgICAgICAgICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgICAgICAgICAgICAgICAgICAgIHR5cGU9InJvdGF0ZSIgICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiICAgICAgICAgICAgICAgICAgICB0bz0iMzYwIDE4IDE4IiAgICAgICAgICAgICAgICAgICAgZHVyPSIwLjlzIiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+ICAgICAgICAgICAgPC9jaXJjbGU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px 100px;
    z-index: 2; }

.swal2-title {
  line-height: 120%; }

.box {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(226, 234, 238, 0.3);
  box-shadow: 0 3px 5px rgba(156, 160, 171, 0.2);
  margin-bottom: 30px; }
  .box h3 {
    margin-bottom: 20px; }
  .box .n-type1 {
    font-family: "Verlag A", "Verlag B";
    font-style: normal;
    font-weight: 700;
    font-size: 30px; }
  .box .n-type2 {
    font-size: 17px;
    font-weight: 500; }
  .box p strong {
    font-weight: 600;
    font-size: 13px; }
  .box p .text-opaque {
    font-size: 11px;
    color: #9B9B9B; }

btn-tst {
  float: right;
  font-size: 3em; }

h2 {
  color: #acadae;
  margin: 0;
  font-size: 1.5em; }
