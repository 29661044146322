.ServicesUptime .link-back {
  margin-bottom: 20px;
  display: inline-block; }

.ServicesUptime .day-select {
  display: flex;
  margin: 0 0 40px; }
  .ServicesUptime .day-select .day + .day {
    margin-left: 20px; }
  .ServicesUptime .day-select .day input {
    height: auto;
    margin-right: 8px; }

.ServicesUptime .schedule-container {
  display: flex;
  margin: 0 0 40px;
  align-items: flex-end; }
  .ServicesUptime .schedule-container .schedule + .schedule {
    margin-left: 20px; }
  .ServicesUptime .schedule-container .schedule label {
    display: block;
    margin-bottom: 5px; }
  .ServicesUptime .schedule-container .time-estimated {
    margin-left: 20px;
    margin-bottom: 7px; }

.ServicesUptime .button-holder {
  display: flex;
  align-items: center; }
  .ServicesUptime .button-holder span {
    margin: 0 10px; }

.ServicesUptime .Table .TableCol:last-child {
  text-align: right; }

.ServicesUptime .Table .total {
  background-color: #F6F6F6;
  align-items: flex-start; }
  .ServicesUptime .Table .total .TableCol {
    flex-direction: column;
    display: flex;
    align-items: flex-end; }
  .ServicesUptime .Table .total .text-total {
    display: inline-block;
    text-align: left; }
    .ServicesUptime .Table .total .text-total strong {
      display: block;
      font-weight: bold; }
  .ServicesUptime .Table .total .price {
    font-weight: bold;
    display: block;
    font-size: 18px; }

.ServicesUptime .agreeCheckbox {
  margin-bottom: 20px; }
  .ServicesUptime .agreeCheckbox input {
    height: auto;
    margin: 0 10px 20px 0; }

.ServicesUptime .row-total {
  display: flex;
  justify-content: space-between;
  width: 580px; }
  .ServicesUptime .row-total .price-now {
    font-weight: bold; }
  .ServicesUptime .row-total + .row-total {
    margin-top: 20px; }

.ServicesUptime .MessagePurchase span {
  background-color: #ffe8ab;
  padding: 20px;
  margin-bottom: 30px;
  display: block; }

.ServicesUptime .MessagePurchase a {
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  margin-left: 10px; }

.ServicesUptime .its-not {
  display: block;
  margin-bottom: 30px;
  font-style: italic; }

.ScheduleUpdate {
  margin-bottom: 50px; }
  .ScheduleUpdate .select-schedule {
    margin-bottom: 10px; }
    .ScheduleUpdate .select-schedule input {
      height: auto;
      margin-right: 10px; }
