.SettingsMenu {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  margin: 0 0 50px; }
  .SettingsMenu .menu-button {
    border: 0;
    margin: 0 10px 0 0;
    padding: 10px;
    background: none;
    font-size: 22px;
    font-weight: normal !important;
    border-bottom: 2px solid transparent;
    height: 64px; }
    .SettingsMenu .menu-button:hover, .SettingsMenu .menu-button.active {
      border-color: #4a90e2; }
  .SettingsMenu .menu-dropdown {
    position: relative; }
    .SettingsMenu .menu-dropdown .menu-button {
      position: relative;
      z-index: 2;
      background: #fff;
      border-radius: 4px 4px 0 0; }
      .SettingsMenu .menu-dropdown .menu-button:hover {
        border: 1px solid #ccc;
        border-bottom: 0; }
      .SettingsMenu .menu-dropdown .menu-button svg {
        width: 10px;
        height: 10px; }
        .SettingsMenu .menu-dropdown .menu-button svg * {
          fill: #565659; }
    .SettingsMenu .menu-dropdown.active {
      border-bottom: 2px solid #4a90e2; }
    .SettingsMenu .menu-dropdown:hover .menu-button {
      border: 1px solid #ccc;
      border-bottom: 0; }
    .SettingsMenu .menu-dropdown:hover .menu-submenu {
      visibility: visible; }
    .SettingsMenu .menu-dropdown .menu-submenu {
      position: absolute;
      border: 1px solid #ccc;
      padding: 10px;
      background: #fff;
      top: 62px;
      z-index: 1;
      border-radius: 0 4px 4px 4px;
      visibility: hidden; }
      .SettingsMenu .menu-dropdown .menu-submenu .submenu-button {
        background: none;
        border: 0;
        padding: 5px;
        font-weight: normal !important;
        white-space: nowrap;
        color: #5c9ce6; }
        .SettingsMenu .menu-dropdown .menu-submenu .submenu-button:hover {
          color: #777; }
