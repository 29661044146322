.Input {
  position: relative; }
  .Input input, .Input select {
    display: block;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #ebe8e8;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    z-index: 2; }
    .Input input.error, .Input select.error {
      border-color: #e24a4a; }
    .Input input:disabled, .Input select:disabled {
      background-color: #f0f0f0; }
    .Input input::placeholder, .Input select::placeholder {
      color: #999; }
  .Input::before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #757575;
    border-bottom: 0 solid transparent;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; }
  .Input select {
    appearance: none;
    font-size: 1rem;
    background-color: transparent; }
  .Input .error-message {
    color: #e24a4a;
    position: absolute;
    white-space: nowrap; }
