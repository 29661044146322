.Environment .container-box {
  display: flex;
  justify-content: flex-end; }

.Environment .banner {
  margin-bottom: 30px;
  text-align: center; }
  .Environment .banner a {
    display: inline-block; }

.Environment .create-spaces_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 24px; }

.EnvironmentItem {
  align-items: center; }
  .EnvironmentItem .environment-name {
    display: block;
    color: #4a90e2;
    margin: 0 0 5px; }
  .EnvironmentItem .deleted {
    font-size: 12px;
    line-height: 16px;
    display: block;
    width: 75px; }
  .EnvironmentItem .icon-status {
    width: 36px;
    height: 36px; }
    .EnvironmentItem .icon-status * {
      fill: #94999c; }
    .EnvironmentItem .icon-status.ON * {
      fill: #00d896; }
    .EnvironmentItem .icon-status.Blocked * {
      fill: #d0021b; }
  .EnvironmentItem .services {
    display: flex; }
    .EnvironmentItem .services .service {
      display: flex;
      align-items: center; }
      .EnvironmentItem .services .service + .service {
        margin-left: 10px; }
    .EnvironmentItem .services .service-text {
      margin-right: 5px; }
      .EnvironmentItem .services .service-text .qtd,
      .EnvironmentItem .services .service-text .version {
        display: block;
        text-align: right;
        font-size: 11px;
        color: #818181; }
      .EnvironmentItem .services .service-text .qtd {
        margin-bottom: 10px;
        color: #3c3c3c; }
    .EnvironmentItem .services .service-img img {
      display: block;
      max-width: 40px;
      max-height: 40px; }
