.EnvironmentDetail .stage-name {
  margin-bottom: 30px;
  display: inline-block; }

.EnvironmentDetail .badge-blocked,
.EnvironmentDetail .badge-updating {
  display: inline-block;
  background-color: #d0021a;
  color: #fff;
  padding: 3px 10px;
  border-radius: 4px;
  margin-left: 10px; }

.EnvironmentDetail .badge-updating {
  background-color: #FC9D0A; }

.EnvironmentDetail .env-head {
  display: flex;
  justify-content: space-between; }
  .EnvironmentDetail .env-head .col-activate {
    text-align: right; }
    .EnvironmentDetail .env-head .col-activate .main-button {
      margin-bottom: 5px; }
    .EnvironmentDetail .env-head .col-activate .text {
      display: block; }

.EnvironmentDetail .header-services {
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between; }
  .EnvironmentDetail .header-services .title {
    font-size: 25px;
    font-weight: 900; }

.EnvironmentDetail .Table {
  margin: 0 0 30px; }

.EnvironmentDetail .save-changes .cancel {
  background-color: none;
  border: none;
  padding: 0;
  color: #4a90e2; }

.EnvironmentDetail .save-changes .text {
  display: inline-block;
  margin: 0 10px; }

.EnvironmentDetail .tabEnvironment {
  border-bottom: 1px solid #e8e8e8;
  display: flex; }
  .EnvironmentDetail .tabEnvironment .tab-button {
    padding: 10px 10px 8px;
    border: 0;
    background-color: transparent;
    font-weight: normal;
    font-size: 20px;
    border-bottom: 2px solid #fff;
    display: block; }
    .EnvironmentDetail .tabEnvironment .tab-button.active, .EnvironmentDetail .tabEnvironment .tab-button:hover {
      border-bottom: 2px solid #4a90e2; }
    .EnvironmentDetail .tabEnvironment .tab-button + .tab-button {
      margin-left: 10px; }
    .EnvironmentDetail .tabEnvironment .tab-button.disabled {
      color: #ccc; }
    .EnvironmentDetail .tabEnvironment .tab-button.disabled:hover {
      border-bottom: 2px solid #fff; }

.EnvironmentDetail .Access,
.EnvironmentDetail .EnvDetailPage,
.EnvironmentDetail .DeleteSpace {
  margin-top: 60px; }

.EnvironmentDetail button.remove {
  padding: 0;
  border: 0;
  background-color: #fff;
  color: #e24a4a;
  font-weight: normal;
  cursor: pointer; }

.EnvironmentDetail .period-select {
  margin-top: 40px; }

.EnvironmentDetail .SubTab {
  position: relative;
  z-index: 2; }
  .EnvironmentDetail .SubTab .SettingsButton {
    display: block;
    padding: 10px;
    padding-right: 25px;
    position: relative; }
    .EnvironmentDetail .SubTab .SettingsButton svg {
      width: 24px;
      height: 24px;
      margin-top: 8px; }
    .EnvironmentDetail .SubTab .SettingsButton:after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-left: 1px solid #fff;
      border-right: 1px solid #000;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #000;
      transform: rotate(45deg);
      position: absolute;
      right: 0;
      top: calc(50% - 6px); }
  .EnvironmentDetail .SubTab:hover .SettingsButton:after {
    border-left-color: #000;
    border-right-color: #fff;
    border-top-color: #000;
    border-bottom-color: #fff; }
  .EnvironmentDetail .SubTab:hover .SubTabOver {
    display: block; }
  .EnvironmentDetail .SubTab .SubTabOver {
    border: 1px solid #ccc;
    position: absolute;
    left: 0;
    top: 57px;
    width: 150px;
    background-color: #fff;
    display: none; }
    .EnvironmentDetail .SubTab .SubTabOver .tab-button {
      width: 100%;
      text-align: left; }
    .EnvironmentDetail .SubTab .SubTabOver .tab-button + .tab-button {
      margin-left: 0; }

.EnvironmentDetail .DetailsUptime .title {
  font-size: 18px;
  font-weight: bold; }

.EnvironmentDetail .DetailsUptime .text {
  margin: 20px 0; }
  .EnvironmentDetail .DetailsUptime .text strong {
    font-weight: bold; }

.EnvironmentDetail .DetailsUptime .button-change.disabled {
  color: #565656;
  cursor: auto; }

.DangerZone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #e24a4a; }
  .DangerZone .message .title {
    display: block;
    font-weight: bold; }

.MessageHighlight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #d8dde6;
  margin: 0 0 40px; }
  .MessageHighlight .message .title {
    display: block;
    font-weight: bold; }

.AddIp {
  padding: 20px 20px 40px;
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8; }
  .AddIp h3 {
    font-family: "Cairo", sans-serif;
    font-size: 18px;
    font-weight: 900;
    color: #565656;
    text-transform: none;
    margin: 0 0 20px; }
  .AddIp form {
    display: flex;
    align-items: flex-end; }
    .AddIp form > div + div {
      margin-left: 20px; }
  .AddIp .field {
    position: relative; }
    .AddIp .field label {
      display: block;
      margin-bottom: 5px; }
    .AddIp .field input {
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #ccc; }
    .AddIp .field input.error {
      border-color: #e24a4a; }
    .AddIp .field .error_msg {
      display: block;
      position: absolute;
      left: 0;
      bottom: -25px;
      color: #e24a4a;
      background-color: #fbfbfb; }
  .AddIp .period {
    display: inline-block;
    margin: 0 5px;
    font-weight: bold;
    font-size: 20px; }
