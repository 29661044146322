.Card {
  border: 1px solid #ebe8e8;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box; }
  .Card.highlight {
    box-shadow: 0 0 0 5px #b2d5ff inset;
    border: 1px solid #b2d5ff; }
  .Card.pack_ece {
    display: none; }
  .Card.ece.pack_ece {
    display: block; }

.CardList {
  display: flex;
  flex-wrap: wrap;
  margin: -13px;
  position: relative; }
  .CardList .Card {
    width: 240px;
    margin: 13px; }
  .CardList .disable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center; }
