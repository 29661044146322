.TableServices .service-data {
  display: flex;
  align-items: center; }

.TableServices .service-logo {
  max-width: 40px;
  max-height: 40px;
  margin-right: 20px; }

.TableServices .TableCol {
  color: #908a8a;
  display: flex;
  align-items: center; }

.TableServices .service-text .name {
  display: block;
  font-size: 17px;
  font-weight: 600;
  color: #565656;
  margin: 0 0 5px; }

.TableServices .service-text .features span {
  display: inline-block;
  padding: 0 6px;
  line-height: 14px; }
  .TableServices .service-text .features span + span {
    border-left: 1px solid #918c8c; }
  .TableServices .service-text .features span:first-child {
    padding-left: 0; }

.TableServices .edit-button {
  padding: 0;
  border: 0;
  background: none;
  color: #4a90e2; }

.TableServices .cancel-button {
  background: none;
  padding: 0;
  border: none;
  color: #e12020;
  font-size: 12px; }

.TableServices .service-status {
  text-align: center; }
  .TableServices .service-status .status {
    font-size: 12px;
    text-transform: uppercase;
    color: #2cb03d;
    line-height: 14px;
    display: block;
    text-align: center; }

.TableServices .ServiceConfig {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out; }
  .TableServices .ServiceConfig .over-holder {
    display: flex;
    padding: 20px;
    padding-top: 0;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8; }
  .TableServices .ServiceConfig .col {
    width: 50%; }

.TableServices .no-services {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 40px; }
  .TableServices .no-services .title {
    display: block;
    text-align: center;
    font-size: 27px;
    font-weight: bold;
    margin: 0 0 20px; }
  .TableServices .no-services .text {
    display: block;
    text-align: center;
    font-size: 20px; }
    .TableServices .no-services .text button {
      padding: 0;
      background: none;
      border: 0;
      color: #4A90E2; }

.NewService .TableRow {
  border-bottom: 0; }

.NewService .ServiceConfig {
  border-bottom: 1px solid #e8e8e6; }
