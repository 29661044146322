.AddField .autocomplete-item {
  padding: 5px 10px; }
  .AddField .autocomplete-item.highlighted {
    background-color: #f0f0f0; }

.AddField .add-label {
  display: block;
  font-weight: bold;
  margin: 0 0 10px; }

.AddField .box-field {
  display: flex;
  align-items: center; }
  .AddField .box-field input {
    width: 400px;
    padding: 5px 10px;
    border: 1px solid #4a90e2;
    height: 43px;
    border-radius: 4px 0 0 4px;
    background-color: #fff; }
  .AddField .box-field button {
    border: 0;
    background-color: #4a90e2;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 40px;
    border-radius: 0 4px 4px 0; }

.AddField .disable-button {
  position: relative; }
  .AddField .disable-button.disabled::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0; }
