.ServicesControl {
  position: relative; }
  .ServicesControl button + button {
    margin-left: 20px; }
  .ServicesControl.disable:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); }
