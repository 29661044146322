.createEnvironment {
  display: flex;
  align-items: stretch;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden; }
  .createEnvironment-container {
    padding: 72px 0;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    max-width: 1140px;
    margin: 0 auto; }
  .createEnvironment-header {
    margin-bottom: 24px; }
    .createEnvironment-header__title {
      color: var(--basic-level-1);
      font-weight: 700;
      font-size: 28px; }
  .createEnvironment-content__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 48px; }
  .createEnvironment-content__form {
    width: 50%;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-columns: calc(100% / 3 - 48px);
    grid-row: 400px;
    margin-bottom: 64px; }
    .createEnvironment-content__form-actions {
      grid-column: 1 / span 3; }
    .createEnvironment-content__form-button {
      -moz-appearance: none;
      -o-appearance: none;
      -ms-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding: 8px 24px;
      background: var(--primary-level-2);
      color: #fff;
      transition: all 250ms ease;
      border: none;
      border-radius: 4px;
      font-size: 16px; }
      .createEnvironment-content__form-button.is-disabled {
        background: var(--basic-level-3); }
        .createEnvironment-content__form-button.is-disabled:hover {
          cursor: none;
          background: var(--basic-level-3); }
      .createEnvironment-content__form-button:hover {
        cursor: pointer;
        background: var(--primary-level-3); }
