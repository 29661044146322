.Status {
  display: flex;
  padding: 20px;
  align-items: center;
  margin: 0 0 50px;
  justify-content: space-between;
  position: relative; }
  .Status .delete-msg {
    font-style: italic; }
  .Status.AllServicesOffline {
    background-color: #f3f3f5; }
  .Status.Building, .Status.DeployingAllServices, .Status.TurningOffAllServices, .Status.TurningOnAllServices {
    background-color: rgba(252, 157, 10, 0.1); }
    .Status.Building .current-status .text, .Status.DeployingAllServices .current-status .text, .Status.TurningOffAllServices .current-status .text, .Status.TurningOnAllServices .current-status .text {
      color: #fc9d0a; }
  .Status.AllServicesOnline {
    background-color: #ebf8f4; }
    .Status.AllServicesOnline .current-status .text {
      color: #2ab92e; }
  .Status .current-status .title {
    display: block;
    margin-bottom: 5px; }
  .Status .current-status .text {
    font-size: 22px;
    font-weight: bold; }
    .Status .current-status .text.running {
      color: #caa630; }
    .Status .current-status .text.off {
      color: #e24a4a; }
    .Status .current-status .text.normal {
      color: #939393; }
  .Status .right-buttons {
    flex-grow: 1;
    text-align: right; }
    .Status .right-buttons.disabled {
      position: relative; }
      .Status .right-buttons.disabled::after {
        position: absolute;
        content: "";
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5); }
  .Status .status-bar {
    position: absolute;
    left: 0;
    bottom: -8px;
    height: 8px;
    width: 100%;
    background-color: #d8dde6; }
    .Status .status-bar .bar {
      position: absolute;
      left: 0;
      top: 0;
      height: 8px;
      width: 0%;
      background: #204079;
      background: linear-gradient(24deg, #204079 0, #118aca 100%);
      transition: width .6s linear; }
