.TabButtons {
  display: flex; }
  .TabButtons button {
    flex-grow: 1;
    border: 1px solid #4a90e2;
    border-left: 0;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    color: #4a90e2;
    font-weight: normal !important;
    line-height: 100%; }
    .TabButtons button:first-child {
      border-left: 1px solid #4a90e2;
      border-radius: 5px 0 0 5px; }
    .TabButtons button:last-child {
      border-radius: 0 5px 5px 0; }
    .TabButtons button.selected {
      background-color: #B2D5FF;
      color: #10529F; }
