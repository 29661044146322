.Plugins .Plugin {
  border: 1px solid #ccc;
  width: 280px; }
  .Plugins .Plugin.card_1 .title {
    background-image: url("/static/card_1.png"); }
  .Plugins .Plugin.card_2 .title {
    background-image: url("/static/card_2.png"); }
  .Plugins .Plugin.card_3 .title {
    background-image: url("/static/card_3.png"); }
  .Plugins .Plugin + .Plugin {
    margin-left: 25px; }
  .Plugins .Plugin .title {
    background-color: #000;
    height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-position: center; }
    .Plugins .Plugin .title .name {
      padding-left: 108px;
      color: #fff; }
    .Plugins .Plugin .title .category {
      padding-left: 108px;
      color: #fff;
      font-size: 14px; }
  .Plugins .Plugin .description {
    display: block;
    padding: 20px; }

.Plugins .Plugin_Plus {
  border: 1px solid #ccc;
  width: 280px;
  padding: 20px; }
  .Plugins .Plugin_Plus .container-img {
    height: 60px;
    margin-bottom: 10px;
    text-align: center; }
  .Plugins .Plugin_Plus + .Plugin_Plus {
    margin-left: 25px; }
  .Plugins .Plugin_Plus .title {
    margin-bottom: 20px; }
    .Plugins .Plugin_Plus .title .name {
      display: block;
      font-weight: bold;
      font-size: 18px; }
    .Plugins .Plugin_Plus .title .category {
      font-size: 14px;
      color: #9B9B9B; }
  .Plugins .Plugin_Plus .description {
    display: block;
    min-height: 70px;
    margin-bottom: 10px; }
  .Plugins .Plugin_Plus .ask-button {
    text-decoration: none;
    padding: 0;
    background: transparent;
    border: 0;
    font-weight: normal;
    color: #4a90e2; }

.Plugins .cards {
  display: flex;
  margin-bottom: 40px; }

.Plugins .MessagePurchase span {
  background-color: #ffe8ab;
  padding: 20px;
  margin-bottom: 30px;
  display: block; }

.Plugins .MessagePurchase a {
  font-weight: bold;
  text-decoration: none; }

.Plugins .SpacesPlugins .TableHeader .TableCol {
  text-align: center; }
  .Plugins .SpacesPlugins .TableHeader .TableCol:first-child {
    text-align: left; }

.Plugins .SpacePlugin .TableCol {
  text-align: center; }
  .Plugins .SpacePlugin .TableCol input {
    height: auto; }
  .Plugins .SpacePlugin .TableCol:first-child {
    text-align: left; }

.Plugins .TableRow.total {
  background-color: #F6F6F6; }
  .Plugins .TableRow.total .TableCol {
    text-align: right;
    font-weight: bold; }

.Plugins .no-item .title {
  margin-bottom: 0; }

.Plugins .agreeCheckbox input {
  height: auto;
  margin: 0 10px 20px 0; }

.Plugins .purchasing-message {
  margin: 0 0 20px; }

.Plugins .row_button {
  position: relative; }
  .Plugins .row_button.disable::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0; }
