.CreditCard .title-page {
  margin-top: 30px; }

.CreditCard .field {
  margin-bottom: 25px;
  width: 50%; }
  .CreditCard .field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px; }
  .CreditCard .field .Input {
    width: 50%; }
    .CreditCard .field .Input.number {
      width: 170px; }
    .CreditCard .field .Input.big-number {
      width: 340px; }
  .CreditCard .field input,
  .CreditCard .field select {
    display: block;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #ebe8e8;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    z-index: 2; }
    .CreditCard .field input.error,
    .CreditCard .field select.error {
      border-color: #e24a4a; }
    .CreditCard .field input:disabled,
    .CreditCard .field select:disabled {
      background-color: #f0f0f0; }
    .CreditCard .field input::placeholder,
    .CreditCard .field select::placeholder {
      color: #999; }
  .CreditCard .field .error-msg {
    color: #e24a4a; }

.CreditCard .bt-cancel {
  background: none;
  border: 0;
  padding: 0;
  color: #4a90e2;
  font-weight: normal; }

.CreditCard .button-holder {
  display: flex;
  align-items: center; }
  .CreditCard .button-holder span {
    margin: 0 10px; }

.CreditCard .box-disable {
  position: relative; }
  .CreditCard .box-disable.disabled:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5); }

.SelectBuild {
  display: flex; }
  .SelectBuild input {
    display: none !important; }
    .SelectBuild input:checked + label:before {
      background-color: #4a90e2; }
  .SelectBuild label {
    position: relative;
    padding: 3px 0 3px 35px;
    cursor: pointer;
    font-weight: normal !important; }
    .SelectBuild label:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      box-shadow: 0 0 0 3px #fff inset;
      border: 2px solid #4a90e2;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .SelectBuild .element + .element {
    margin-left: 40px; }
