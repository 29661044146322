.UserSpaces .button-back {
  display: inline-block;
  margin: 0 0 20px; }

.UserSpaces .head-user {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 40px; }
  .UserSpaces .head-user .title-page {
    margin: 0 0 20px; }

.UserSpaces .Table-items .space .space-name span {
  display: block; }

.UserSpaces button.remove {
  padding: 0;
  border: 0;
  background-color: #fff;
  color: #e24a4a;
  font-weight: normal;
  cursor: pointer; }

.UserSpaces .Container {
  margin: 0 0 60px; }

.UserSpaces .danger-zone {
  border: 1px solid #e24a4a;
  padding: 20px;
  display: flex;
  justify-content: space-between; }
  .UserSpaces .danger-zone .text span {
    display: block; }
  .UserSpaces .danger-zone .text .title {
    font-weight: bold; }
  .UserSpaces .danger-zone .main-button {
    background-color: #e24a4a; }
