.BillingDate .title-page {
  margin-top: 40px; }

.BillingDate .select-date {
  display: flex;
  margin-bottom: 40px; }
  .BillingDate .select-date .billing-date input {
    height: auto;
    margin-right: 10px; }
  .BillingDate .select-date .billing-date + .billing-date {
    margin-left: 40px; }

.BillingDate .button-holder {
  display: flex;
  align-items: center; }
  .BillingDate .button-holder span {
    margin: 0 15px; }

.BillingDate .bt-link {
  padding: 0;
  background: transparent;
  border: 0;
  color: #4a90e2; }
