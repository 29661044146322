.notfound {
  display: flex;
  align-items: flex-end; }
  .notfound p {
    font-size: 20px;
    margin: 0 0 20px; }
  .notfound ul {
    margin: 0 0 30px;
    list-style: inside; }
  .notfound img {
    margin-right: 40px; }
